import { mapActions, mapGetters } from 'vuex'

const Modal = () => import(/* webpackChunkName = "c-modal" */'@/components/Modal')
const emptyField = (name) => `${name} tidak boleh kosong`

export default {
  name: 'AddArticleForm',
  Components: {
    Modal
  },
  props: {
    articleData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      title: '',
      chooseDate: '',
      summary: '',
      imageUrl: '',
      contents: '',
      type: 'DEFAULT',
      youtubeVideoId: '',
      pdfId: '',
      isEdit: false,
      errors: []
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapGetters('user', ['token']),
    requestData () {
      const dateInMillis = new Date(this.chooseDate).getTime()
      return {
        id: this.articleData.id,
        article: {
          contents: this.contents,
          date: dateInMillis,
          imageUrl: this.imageUrl,
          summary: this.summary,
          title: this.title,
          type: this.type,
          youtubeVideoId: this.youtubeVideoId,
          pdfId: this.pdfId,
          token: this.token
        }
      }
    },
    errorsText () {
      return this.errors.join(', ')
    }
  },
  methods: {
    ...mapActions('article', ['createArticleAction', 'editArticleAction', 'getArticlesAction']),
    close () {
      this.$emit('close')
    },
    initPage () {
      if (Object.keys(this.articleData).length > 0) {
        const { title, summary, imageUrl, contents, date, type, youtubeVideoId, pdfId } = this.articleData
        const isoDate = new Date(date).toISOString()
        this.title = title
        this.summary = summary
        this.imageUrl = imageUrl
        this.contents = contents
        this.isEdit = true
        this.chooseDate = isoDate
        this.type = type || 'DEFAULT'
        this.youtubeVideoId = youtubeVideoId
        this.pdfId = pdfId
      }
    },
    handleSubmit () {
      this.validateForm()
      if (this.errors.length === 0) {
        if (this.isEdit) {
          this.editArticleAction({
            data: this.requestData,
            success: this.handleSuccessSubmit
          })
        } else {
          this.createArticleAction({
            data: this.requestData,
            success: this.handleSuccessSubmit
          })
        }
      }
    },
    handleSuccessSubmit () {
      this.$swal({
        icon: 'success',
        title: 'Success Submit',
        showConfirmButton: false,
        timer: 1500
      })
      this.close()
      this.$emit('successRequest')
    },
    validateForm () {
      this.errors = []
      if (this.title === '') this.errors.push(emptyField('Judul'))
      if (this.chooseDate === '') this.errors.push(emptyField('Tanggal'))
      if (this.summary === '') this.errors.push(emptyField('Summary'))
      if (this.imageUrl === '') this.errors.push(emptyField('Alamat Gambar'))
      if (this.contents === '') this.errors.push(emptyField('Isi'))
      if (this.type === 'YOUTUBE' && this.youtubeVideoId === '') this.errors.push(emptyField('Youtube video Id'))
    }
  }
}
